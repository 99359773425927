.wrapper_grid {
  @apply grid grid-rows-5 gap-4;
}

.main_grid {
  @apply row-span-2 grid grid-cols-2 gap-4;
}

.side_grid {
  @apply grid grid-cols-3 gap-4;
}

.image {
  @apply rounded-3xl object-cover;
}

.image_wide {
  @apply relative col-span-2;
}

.content {
  @apply flex flex-col gap-4;
}
