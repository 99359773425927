.wrapper_grid {
  @apply relative grid grid-cols-4 gap-4;
}
.main_grid {
  @apply col-span-2 grid grid-cols-2 grid-rows-3 gap-4;
}
.side_grid {
  @apply grid grid-rows-3 gap-4;
}
.main_image {
  @apply relative col-span-2 row-span-2;
}
.tall_image {
  @apply relative row-span-2;
}
.image {
  @apply rounded-3xl object-cover;
}
.content {
  @apply absolute left-1/2 top-1/2 flex max-w-full -translate-x-1/2 -translate-y-1/2 flex-col gap-4 rounded-3xl bg-white bg-opacity-80 p-6 shadow-lg backdrop-blur-md;
}
.polygon {
  @apply absolute -left-56 top-1/3 -z-10;
}
.circle {
  @apply absolute -right-36 top-1/2 -z-10;
}
