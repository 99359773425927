.root {
  @apply relative py-12;
}
.overlay {
  @apply absolute bottom-0 left-0 right-0 top-0 bg-[#EDEDED] lg:bottom-60;
}
.description {
  @apply mx-auto mb-10 max-w-3xl text-center;
}
.button {
  @apply mt-10 flex items-center justify-center;
}
.slide {
  @apply flex-shrink-0 flex-grow-0 basis-full;
}
.slide_item {
  @apply mx-auto grid max-w-[324px] grid-cols-4 gap-3 pb-5 md:max-w-[496px] md:gap-8 xl:max-w-5xl xl:grid-cols-8;
}
.slide_item_link {
  @apply relative block h-[72px] w-[72px] overflow-hidden rounded-2xl bg-white shadow-lg md:h-[100px] md:w-[100px];
}
.carousel_button {
  @apply absolute top-1/2 -m-2 hidden -translate-y-1/2 items-center justify-center sm:flex;
}
