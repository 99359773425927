.root {
  @apply my-20;
}
.slide {
  @apply mr-4 min-w-0 flex-shrink-0 flex-grow-0 basis-80 lg:mr-6;
}
.card {
  @apply relative flex h-full flex-col overflow-hidden rounded-3xl bg-white text-default shadow-lg;
}
.card_media {
  @apply relative h-56;
}
.card_media img {
  @apply object-cover;
}
.card_content {
  @apply flex flex-1 flex-col gap-1 p-5;
}
.card_title a {
  @apply text-lg font-bold text-default;
}
.card_title a:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.card_date {
  @apply mb-6 text-gray-600;
}
.card_text {
  @apply line-clamp-3;
}
.arrow_buttons {
  @apply hidden gap-4 lg:flex;
}
.actions {
  @apply mt-10 flex justify-center;
}
