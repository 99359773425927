.h1 {
  @apply text-3xl;
}

.h1 strong {
  @apply block;
}
.h2 {
  @apply text-2xl md:text-3xl;
}
