.root {
  @apply relative mt-12;
}
.scrollable_area {
  @apply overflow-hidden px-4 pb-10;
}
.scrollable_content {
  @apply -ml-4 flex md:-ml-6;
}
.slide {
  @apply min-w-0 flex-shrink-0 flex-grow-0 basis-3/4 pl-4 md:basis-1/2 md:pl-6 lg:basis-1/3;
}
.card {
  @apply relative flex h-72 flex-col overflow-hidden rounded-3xl shadow-lg;
}
.card_content {
  @apply relative flex flex-1 flex-col justify-end bg-opacity-50 bg-gradient-to-t from-[#0f172ab0] to-transparent p-6;
}
.card_title a {
  @apply text-lg font-bold text-white;
}
.card_title a:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.carousel_button {
  @apply absolute top-1/2 -mt-8 hidden items-center justify-center md:flex;
}
