.root {
  @apply relative mb-12;
}

.wrapper {
  @apply grid grid-cols-1 items-start gap-4 md:grid-cols-5;
}

.left_col {
  @apply flex md:col-span-2;
}

.left_col_content {
  @apply relative w-full pb-8;
}

.image_wrapper {
  @apply relative h-[298px] w-full overflow-hidden rounded-3xl;
}

.placeholder {
  @apply absolute bottom-0 left-8 w-56 rounded-2xl bg-primary bg-opacity-70 p-4 font-bold text-white shadow-lg shadow-blue-500/50 backdrop-blur-sm;
}

.card {
  @apply flex min-h-[298px] flex-col gap-4 rounded-3xl bg-[#FFF4E8] p-6 md:col-span-3;
}

.manage_booking_button {
  @apply mt-auto;
}
