.pseudo_map {
  @apply relative h-[70vh] w-full;
}
.pseudo_map_image {
  @apply object-cover;
}
@media (min-width: 1920px) {
  .pseudo_map_image {
    @apply object-contain;
  }
}
