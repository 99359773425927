.root {
  @apply relative bg-primary py-6 text-white;
}
.preview_image {
  @apply absolute -bottom-6 -left-28 hidden w-[512px] xl:block;
}
.download_app_wrapper {
  @apply flex flex-col items-center gap-4 sm:items-start;
}

.logo_header {
  @apply flex flex-row gap-4 sm:flex-col;
}
